import React, {
  useState,
  useRef,
  useMemo,
  memo,
  useEffect,
  useContext,
  useCallback
} from 'react';
import classNames from 'classnames';
import { Row, Col } from 'antd';
import { pdfjs, Document, Page } from 'react-pdf';
import { useDispatch } from 'react-redux';
import VirtualList, { ListRef } from 'rc-virtual-list';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useTypedSelector } from '@hooks';
import {
  selectComment,
  setActivePageNumber,
  setDocumentHighlight,
  setTurnoverDegree,
  setZoomFactorAction
} from '@redux/actions/mediaViewerAction';
import AssetInfo from '@pages/MediaViewer/AssetViewer/AssetInfo';
import { ReactComponent as PagesSvg } from '@assets/icons/MediaViewer/pages.svg';
import { ReactComponent as ArrowRightSvg } from '@assets/icons/arrow-right-without-line.svg';
import { ReactComponent as ArrowLeftSvg } from '@assets/icons/arrow-left-without-line.svg';
import { ReactComponent as TSvg } from '@assets/icons/t.svg';
import PanelAction from '@pages/MediaViewer/AssetViewer/PanelAction';
import PlaybackPreparingLoader from '@pages/MediaViewer/AssetViewer/PlaybackPreparingLoader';
import { AssetVersionItemDto, DocumentMetadataDto } from '@api/Api';
import { FabricContext } from '@context/FabricContext';
import FabricCanvas from '@pages/MediaViewer/AssetViewer/Canvas/Canvas';
import PageThumbnail from '@pages/MediaViewer/AssetViewer/PdfCard/components/PageThumbnail';
import type { fabric } from 'fabric';
import 'react-pdf/dist/Page/TextLayer.css';
import './PdfCard.less';
import { highlightCreator } from '@pages/MediaViewer/AssetViewer/Canvas/Objects/highlightHandler';

if (typeof (Promise as any).withResolvers === 'undefined') {
  (Promise as any).withResolvers = () => {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

// https://github.com/mozilla/pdf.js/blob/3b615e4ca369ff31e6399dea80383ffeffe96240/web/viewer_compatibility.js#L42-L48
const maxCanvasSize = 2048;

function extractTextFromNode(node: Node): string {
  if (node.nodeName === 'BR') return '\n';
  if (node.nodeType === Node.TEXT_NODE) return node.textContent || '';
  if (node.nodeType === Node.ELEMENT_NODE)
    return Array.from(node.childNodes).map(extractTextFromNode).join('');
  return '';
}

interface IPdfCardProps {
  isExpand: boolean;
  setIsExpand(val: boolean): void;
  onReady: (a?: any) => any;
  asset: AssetVersionItemDto;
  isPreparing: boolean;
}

export default memo(function PdfCard({
  isExpand,
  setIsExpand,
  onReady,
  asset,
  isPreparing
}: IPdfCardProps) {
  const fabricContext = useContext(FabricContext);
  const dispatch = useDispatch();
  const listRef = useRef<ListRef>(null);
  const wrapperRef = useRef<OverlayScrollbarsComponent>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const documentRef = useRef<HTMLDivElement>(null);
  const isLoadPdf = useRef<boolean>(false);
  const canvasContainerRef = useRef<HTMLImageElement>(null);
  const buttonSelectedTextRef = useRef<HTMLButtonElement>(null);

  const fileUrl = useMemo(() => {
    if (isPreparing) return null;
    const { proxy } = asset.metadata as DocumentMetadataDto;
    return proxy.url || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset.id, isPreparing]);

  const [pagesCount, setPagesCount] = useState(1);

  const selectedCommentId = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedCommentId
  );
  const isToolsExpanded = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.isToolsExpanded
  );
  const shapesDrawingTools = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.shapesDrawingTools
  );
  const activePageNumber = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.activePageNumber
  );

  const zoomFactor = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.zoomFactor
  );
  const turnoverDegree = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.turnoverDegree
  );
  const documentHighlight = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.documentHighlight
  );
  const documentHighlightRef = useRef(documentHighlight);

  const [isShowBtn, setIsShowBtn] = useState<boolean>(false);
  const [isShowPages, setIsShowPages] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<{ width: number; height: number }>({
    width: 1605,
    height: 2272
  });

  const _pageSize = useMemo(() => {
    return {
      width:
        pageSize.width > pageSize.height
          ? pageSize.width * zoomFactor
          : undefined,
      height:
        pageSize.height > pageSize.width
          ? pageSize.height * zoomFactor
          : undefined
    };
  }, [pageSize, zoomFactor]);

  const pagesArray = useMemo(
    () =>
      new Array(pagesCount).fill('').map((_, index) => ({ page: index + 1 })),
    [pagesCount]
  );

  const onSelectText = (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    dispatch(setDocumentHighlight(documentHighlightRef.current));
  };

  const onClearHighlightObjects = useCallback(() => {
    const { canvas } = fabricContext;
    if (!canvas) return;
    const getHighlightObjects = canvas
      .getObjects()
      .filter((item) => item.type === 'highlight');
    getHighlightObjects.forEach((obj) => canvas.remove(obj));
    canvas.fire('object:history:remove');
  }, [fabricContext.canvas]);

  const renderTextHighlights = useCallback(
    (rects: any) => {
      if (!fabricContext.canvas) return;
      onClearHighlightObjects();
      highlightCreator({
        canvas: fabricContext.canvas,
        documentHighlightRects: rects
      });
    },
    [fabricContext.canvas, onClearHighlightObjects]
  );

  useEffect(() => {
    if (!selectedCommentId) return;
    if (
      documentHighlightRef.current?.rects?.length &&
      !documentHighlight.rects?.length
    ) {
      onClearHighlightObjects();
    }
  }, [selectedCommentId, documentHighlight.rects, onClearHighlightObjects]);

  useEffect(() => {
    const handleSelectionchange = () => {
      const selection = window.getSelection();
      const selectedText = selection?.toString?.()?.trim() || '';
      const pageCanvas = pageCanvasRef.current;

      if (selectedCommentId && !documentHighlight?.rects?.length)
        onClearHighlightObjects();

      if (!selectedText?.length || !selection || !pageCanvas) {
        setIsShowBtn(false);
        return;
      }
      setIsShowBtn(true);
      const range = selection.getRangeAt(0);
      const rects = range.getClientRects();
      const buttonSelectedText = buttonSelectedTextRef.current;
      const fragment = range.cloneContents();
      const canvasRect = pageCanvas.getBoundingClientRect();
      const documentContainer = documentRef.current?.querySelector(
        '.textLayer'
      ) as HTMLDivElement;
      if (!buttonSelectedText || !documentContainer || !rects.length) {
        setIsShowBtn(false);
        return;
      }
      const { startContainer, endContainer } = range;

      if (
        !documentContainer.contains(startContainer) ||
        !documentContainer.contains(endContainer)
      ) {
        const newRange = document.createRange();
        if (documentContainer.contains(startContainer)) {
          newRange.setStart(startContainer, range.startOffset);
        } else {
          newRange.setStart(documentContainer, 0);
        }
        if (documentContainer.contains(endContainer)) {
          newRange.setEnd(endContainer, range.endOffset);
        } else {
          newRange.setEnd(
            documentContainer,
            documentContainer.childNodes.length - 1
          );
        }
        selection.removeAllRanges();
        selection.addRange(newRange);
      }

      // Get selection rectangles in viewport coordinates
      const selectionRects = Array.from(range.getClientRects()).filter(
        (rect) => rect.width > 0 && rect.height > 0
      );
      const selectedCoords = selectionRects.map((rect) => {
        const left = rect.left - canvasRect.left;
        const top = rect.top - canvasRect.top;
        const { width } = rect;
        const { height } = rect;
        // Convert to percentages
        const leftPercent = (left / canvasRect.width) * 100;
        const topPercent = (top / canvasRect.height) * 100;
        const widthPercent = (width / canvasRect.width) * 100;
        const heightPercent = (height / canvasRect.height) * 100;

        return {
          left: leftPercent,
          top: topPercent,
          width: widthPercent,
          height: heightPercent
        };
      });
      documentHighlightRef.current = {
        text: Array.from(fragment.childNodes)
          .map(extractTextFromNode)
          .join('')
          .trim(),
        rects: selectedCoords
      };
      renderTextHighlights(selectedCoords);
      const documentRect = documentContainer?.getBoundingClientRect();
      const lastRect = rects[rects.length - 1];
      buttonSelectedText.style.left = `${
        lastRect.right - documentRect.left + 8
      }px`;
      buttonSelectedText.style.top = `${lastRect.bottom - documentRect.top}px`;
    };
    document.addEventListener('selectionchange', handleSelectionchange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionchange);
    };
  }, [selectedCommentId, fabricContext.canvas, documentHighlight?.rects]);

  useEffect(() => {
    if (!listRef.current) return;
    listRef.current.scrollTo({
      index: activePageNumber - 1
    });
  }, [isShowPages, activePageNumber]);

  useEffect(() => {
    isLoadPdf.current = false;
    wrapperRef.current?.osInstance()?.scroll({ x: 0, y: 0 });
  }, [activePageNumber]);

  useEffect(() => {
    if (!selectedCommentId) return;
    if (selectedCommentId === 'new') return;
    const intId = setTimeout(() => {
      const getObjects = fabricContext.canvas?.getObjects() || [];
      const wrapper = wrapperRef.current;
      if (!getObjects.length || !wrapper) return;
      const firstObject: fabric.Object = getObjects[0];
      const objectCoord = firstObject.getCoords()[0];
      const coord = {
        x: objectCoord.x - firstObject.getScaledWidth(),
        y: objectCoord.y - firstObject.getScaledHeight()
      };
      const viewportContainer = wrapper.osInstance().getElements().viewport;
      const isInViewport =
        coord.x >= viewportContainer.scrollLeft &&
        coord.x <= viewportContainer.clientWidth &&
        coord.y >= viewportContainer.scrollTop &&
        coord.y <= viewportContainer.scrollTop + viewportContainer.clientHeight;
      if (!isInViewport) wrapper.osInstance().scroll(coord);
    }, 250);
    return () => {
      clearInterval(intId);
    };
  }, [selectedCommentId, documentHighlight.rects]);

  useEffect(() => {
    const container = wrapperRef?.current?.osTarget?.();
    const document = documentRef?.current;
    if (!container || !document) return;
    const { width } = pageSize;
    const containerWidth = container.offsetWidth;
    if (width > containerWidth) {
      const scaleWidth = Math.floor(containerWidth / (width / 100));
      dispatch(setZoomFactorAction(scaleWidth / 100));
    }
  }, [dispatch, pageSize]);

  useEffect(() => {
    const document = documentRef?.current;
    const content = contentRef?.current;
    const canvasContainer = canvasContainerRef?.current;
    if (!document || !canvasContainer || !content || !fabricContext.canvas)
      return;
    const rect = document.getBoundingClientRect();
    canvasContainer.style.width = `${rect.width}px`;
    canvasContainer.style.height = `${rect.height}px`;
    canvasContainer.style.translate = `-${Math.round(
      rect.width / 2
    )}px -${Math.round(rect.height / 2)}px`;
    content.style.width = `${rect.width}px`;
    content.style.height = `${rect.height}px`;
    fabricContext.canvas.setWidth(rect.width);
    fabricContext.canvas.setHeight(rect.height);
    fabricContext.canvas.setViewportTransform([
      zoomFactor * 10,
      0,
      0,
      zoomFactor * 10,
      0,
      0
    ]);
  }, [pageSize, zoomFactor, fabricContext.canvas, turnoverDegree]);

  const onResetPosition = useCallback(() => {
    isLoadPdf.current = false;
  }, []);

  const onChangePage = useCallback(
    (page: number) => {
      onResetPosition();
      dispatch(setActivePageNumber(page));
      dispatch(selectComment(null));
      dispatch(setDocumentHighlight(null));
    },
    [onResetPosition, dispatch, setActivePageNumber, selectComment]
  );

  const pageCanvasRef = useRef<HTMLCanvasElement>(null);
  const onLoadSuccess = useCallback(
    (pdfPage: { originalWidth: number; originalHeight: number }) => {
      if (isLoadPdf.current) return;
      isLoadPdf.current = true;
      const { originalWidth, originalHeight } = pdfPage;
      const scale = Math.min(
        maxCanvasSize / originalWidth,
        maxCanvasSize / originalHeight
      );
      setPageSize({
        width: originalWidth * scale,
        height: originalHeight * scale
      });
    },
    [setPageSize]
  );

  if (isPreparing) return <PlaybackPreparingLoader />;

  return (
    <Document
      className={classNames('media_viewer_pdf', {
        'b-hide-shapes-drawing':
          shapesDrawingTools?.shape === 'documentHighlight' && isToolsExpanded
      })}
      file={fileUrl}
      onLoadSuccess={(pdf) => {
        setPagesCount(pdf.numPages);
        onReady();
      }}
      loading={<PlaybackPreparingLoader />}
    >
      {isShowPages && (
        <div className="media_viewer_pdf-sidebar">
          <VirtualList
            className="media_viewer_pdf-list"
            data={pagesArray}
            height={wrapperRef?.current?.osTarget()?.offsetHeight || 500}
            fullHeight={true}
            itemKey="page"
            ref={listRef}
          >
            {(item) => {
              return (
                <PageThumbnail
                  page={item.page}
                  active={item.page === activePageNumber}
                  maxWidth={102}
                  maxHeight={147}
                  onClick={onChangePage}
                />
              );
            }}
          </VirtualList>
        </div>
      )}
      <PanelAction
        zoom={zoomFactor}
        setZoom={(v) => {
          dispatch(setZoomFactorAction(v));
        }}
        isExpand={isExpand}
        setIsExpand={(v) => {
          onResetPosition();
          setIsExpand(v);
        }}
        setRotate={(v) => {
          dispatch(
            setTurnoverDegree(
              turnoverDegree + v === 360 ? 0 : turnoverDegree + v
            )
          );
        }}
      />
      <Row
        className="media_viewer_pdf-action"
        gutter={8}
        align="stretch"
        wrap={false}
      >
        <Col>
          <Row className="media_viewer_pdf-action-pages">
            <Col>
              <button
                className="media_viewer_pdf-btn"
                type="button"
                disabled={activePageNumber === 1}
                onClick={() => onChangePage(activePageNumber - 1)}
              >
                <ArrowLeftSvg />
              </button>
            </Col>
            <Col>
              {activePageNumber} of {pagesCount}
            </Col>
            <Col>
              <button
                disabled={activePageNumber === pagesCount}
                className="media_viewer_pdf-btn"
                type="button"
                onClick={() => onChangePage(activePageNumber + 1)}
              >
                <ArrowRightSvg />
              </button>
            </Col>
          </Row>
        </Col>
        <Col>
          <button
            className="media_viewer_pdf-btn media_viewer_pdf-action-btn"
            type="button"
            onClick={() => setIsShowPages(!isShowPages)}
          >
            <PagesSvg />
          </button>
        </Col>
      </Row>
      <OverlayScrollbarsComponent
        className="media_viewer_pdf-wrapper"
        ref={wrapperRef}
      >
        <div className="media_viewer_pdf-content" ref={contentRef}>
          <div
            className="media_viewer_pdf-inner"
            ref={documentRef}
            style={{
              transform: `rotate(${turnoverDegree}deg)`,
              ..._pageSize
            }}
          >
            <Page
              width={_pageSize.width}
              height={_pageSize.height}
              scale={1}
              pageNumber={activePageNumber}
              className="media_viewer_pdf-page"
              canvasRef={pageCanvasRef}
              renderAnnotationLayer={false}
              onLoadSuccess={onLoadSuccess}
              loading={<PlaybackPreparingLoader />}
            />
          </div>
          {isShowBtn && (
            <button
              ref={buttonSelectedTextRef}
              className="media_viewer_pdf-selected-btn"
              type="button"
              onClick={onSelectText}
              onTouchStart={onSelectText}
            >
              <TSvg className="b-white-icon" />
            </button>
          )}
          <div ref={canvasContainerRef} className="media_viewer_pdf-canvas">
            <FabricCanvas />
          </div>
        </div>
      </OverlayScrollbarsComponent>
      {isExpand && (
        <div className="media_viewer_information">
          <AssetInfo />
        </div>
      )}
    </Document>
  );
});
