import React, { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Layout, message } from 'antd';
import MediaViewerHeader from '@components/MediaViewerHeader';
import {
  createNewComment,
  removeComment,
  editComment,
  setCommentLike,
  getAssetInternalComments,
  setAssetVariant,
  setAsset
} from '@redux/actions/mediaViewerAction';
import {
  UpdateAssetReviewCommentDto,
  SetAssetReviewCommentLikeDto,
  PageDto,
  AssetVersionCommentMentionDto,
  CreateAWCommentDto,
  ReplyAWCommentDto
} from '@api/Api';
import { useAssetReview } from '@components/AssetReviewProvider';
import { useTypedSelector } from '@hooks';
import { nonNull } from '@helpers/non-null';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription
} from '@hooks/useWebSocketClient';
import { MediaViewerActionTypes } from '@redux/types/mediaViewerType';
import { useAuth } from '@hooks/useAuth';
import {
  approvalWorkflowAddNotificationSubscription,
  approvalWorkflowGetAssetByAw,
  assetAwCommentsCreateAssetComment,
  assetAwCommentsDeleteAssetComment,
  assetAwCommentsGetAssetComment,
  assetAwCommentsGetAssetComments,
  assetAwCommentsListCommentPotentialMentions,
  assetAwCommentsReplyAssetComment,
  assetAwCommentsSetAssetCommentLike,
  assetAwCommentsUpdateAssetComment
} from '@api/Approval';
import { ApprovalReviewerPage } from '@pages/ApprovalReviewer/ApprovalReviewer';
import AccessClose from '@pages/ExternalReviewer/AccessClose';
import { toggleApprovalModal } from '@redux/actions/modalAction';
import { assetSetRecentlyOpened } from '@api/Asset';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { useFetch } from '@hooks/useFetch';
import { organizationItemStateSelector } from '@redux/selectors/organizations';
import {
  fetchOrganizationSeatsUsed,
  fetchOrganizationStorageUsed,
  fetchOrganizationSubscription
} from '@redux/actions/organizations';
import { apiClient } from '@api/client';

const { Header, Content } = Layout;

type ApprovalReviewerMediaLayoutProps = {
  layoutType: 'asset' | 'batch-assets-list';
};

function ApprovalReviewerMediaLayout({
  layoutType
}: ApprovalReviewerMediaLayoutProps) {
  if (layoutType === 'batch-assets-list')
    return <ApprovalReviewerBatchAssetsList />;
  if (layoutType === 'asset') return <ApprovalReviewerAsset />;
  return null;
}

function ApprovalReviewerAsset() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { user } = useAuth();

  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const asset = useTypedSelector(({ mediaViewer }) => mediaViewer.assets);
  const organizationId = asset?.organizationId;
  const isDisabledOrganization =
    user?.type !== 'internal' || !asset?.organizationId;

  const { reviewId, password } = useAssetReview();

  const assetId = asset?.id;

  const isExistVersion = useMemo(() => {
    if (!asset?.asset?.versions.length) return false;
    if (!assetId) return false;
    return asset.asset.versions.some((item) => item.id === reviewId);
  }, [asset, reviewId]);

  useEffect(() => {
    if (!user || !assetId || !reviewId) return;
    (async () => {
      await assetSetRecentlyOpened({ assetId, assetVersionId: reviewId });
    })();
  }, [user, assetId, reviewId]);

  useEffect(() => {
    if (!user || !assetId) return;
    if (!isExistVersion) return;
    approvalWorkflowAddNotificationSubscription({
      assetId,
      assetVersionId: nonNull(reviewId),
      password
    });
  }, [reviewId, password, isExistVersion, user, assetId]);

  const outletContext = useMemo(() => {
    const params = {
      _assetVersionId: nonNull(reviewId),
      _approvalWorkflowPassword: password
    };
    return {
      addNewComment: async (
        data: CreateAWCommentDto | ReplyAWCommentDto,
        parentId: string
      ) => {
        try {
          let newComment = null;
          if (parentId && parentId !== 'new') {
            const {
              data: { comment }
            } = await assetAwCommentsReplyAssetComment({
              ...(data as ReplyAWCommentDto),
              assetVersionId: params._assetVersionId,
              approvalWorkflowPassword: params._approvalWorkflowPassword
            });
            newComment = comment;
          } else {
            const {
              data: { comment }
            } = await assetAwCommentsCreateAssetComment({
              ...(data as CreateAWCommentDto),
              assetVersionId: params._assetVersionId,
              approvalWorkflowPassword: params._approvalWorkflowPassword
            });
            newComment = comment;
          }
          dispatch(createNewComment(newComment));
          return newComment;
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
          throw error;
        }
      },
      removeComment: async (id: string) => {
        try {
          await assetAwCommentsDeleteAssetComment({
            id,
            assetVersionId: params._assetVersionId,
            approvalWorkflowPassword: params._approvalWorkflowPassword
          });
          dispatch(removeComment(id));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      editComment: async (data: UpdateAssetReviewCommentDto) => {
        try {
          const {
            data: { comment }
          } = await assetAwCommentsUpdateAssetComment({
            ...data,
            assetVersionId: params._assetVersionId,
            approvalWorkflowPassword: params._approvalWorkflowPassword
          });
          dispatch(editComment(comment));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      setCommentLike: async (data: SetAssetReviewCommentLikeDto, user: any) => {
        try {
          await assetAwCommentsSetAssetCommentLike({
            ...data,
            assetVersionId: params._assetVersionId,
            approvalWorkflowPassword: params._approvalWorkflowPassword
          });
          dispatch(setCommentLike(data, user));
        } catch (error: any) {
          message.error({
            content: 'Something went wrong',
            className: 'message-dark-modal'
          });
        }
      },
      getPotentialMentions: async ({
        assetVersionId,
        searchQuery
      }: {
        assetVersionId: string;
        searchQuery?: string;
      }): Promise<AssetVersionCommentMentionDto[]> => {
        const {
          data: { edges }
        }: {
          data: PageDto & { edges: { node: AssetVersionCommentMentionDto }[] };
        } = await assetAwCommentsListCommentPotentialMentions({
          assetVersionId,
          searchQuery,
          approvalWorkflowPassword: params._approvalWorkflowPassword
        });
        if (edges) {
          const list = edges.map((item) => item.node);
          return list;
        }
        return [];
      },
      getPotentialGroupMentions: async ({
        assetVersionId,
        searchQuery
      }: {
        assetVersionId: string;
        searchQuery?: string;
      }): Promise<AssetVersionCommentMentionDto[]> => {
        const {
          data: { edges }
        }: {
          data: PageDto & { edges: { node: AssetVersionCommentMentionDto }[] };
        } =
          await apiClient.approvalWorkflow.assetAwCommentsControllerListCommentPotentialGroupMentions(
            {
              assetVersionId,
              searchQuery,
              approvalWorkflowPassword: params._approvalWorkflowPassword
            }
          );
        if (edges) {
          const list = edges.map((item) => item.node);
          return list;
        }
        return [];
      }
    };
  }, [dispatch, password, reviewId]);

  const getCommentsList = useCallback(
    async (assetVersionId: string) => {
      try {
        const {
          data: { comments }
        } = await assetAwCommentsGetAssetComments({
          assetVersionId,
          approvalWorkflowPassword: password
        });
        dispatch(getAssetInternalComments(comments));
      } catch (error: any) {
        message.error({
          content: 'Something went wrong',
          className: 'message-dark-modal'
        });
      }
    },
    [dispatch, password]
  );

  useEffect(() => {
    if (!selectedVersion?.id) return;
    getCommentsList(selectedVersion.id);
  }, [selectedVersion?.id, getCommentsList]);

  useFetch({
    disabled: isDisabledOrganization,
    key: `organizations-subscription-${organizationId}`,
    selector: (state) =>
      organizationItemStateSelector(state, { organizationId: organizationId! })
        .subscription.fetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationSubscription({
          fetchType,
          organizationId: organizationId!
        })
      )
  });
  useFetch({
    disabled: isDisabledOrganization,
    key: `organizations-seats-used-${organizationId}`,
    selector: (state) =>
      organizationItemStateSelector(state, { organizationId: organizationId! })
        .seatsCount.countFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationSeatsUsed({
          fetchType,
          organizationId: organizationId!
        })
      )
  });
  useFetch({
    disabled: isDisabledOrganization,
    key: `organizations-storage-used-${organizationId}`,
    selector: (state) =>
      organizationItemStateSelector(state, { organizationId: organizationId! })
        .assetsSummarySize.sizeFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationStorageUsed({
          fetchType,
          organizationId: organizationId!
        })
      )
  });

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.assetId !== asset?.id) return false;
    if (msg.payload.assetVersionId !== selectedVersion?.id) return false;
    if (msg.causedByMe) return;
    const {
      data: { comment }
    } = await assetAwCommentsGetAssetComment({
      id: msg.payload.commentId,
      assetVersionId: nonNull(reviewId),
      approvalWorkflowPassword: password
    });
    dispatch(createNewComment(comment));
  }, 'asset-comment-created');

  useWebSocketSubscription(async (msg) => {
    if (msg.payload.assetId !== asset?.id) return false;
    if (msg.payload.assetVersionId !== selectedVersion?.id) return false;
    if (msg.causedByMe) return;

    dispatch(removeComment(msg.payload.commentId));
  }, 'asset-comment-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];

      const {
        data: { comment }
      } = await assetAwCommentsGetAssetComment({
        id: lastMessage.payload.commentId,
        assetVersionId: nonNull(reviewId),
        approvalWorkflowPassword: password
      });
      dispatch({
        type: MediaViewerActionTypes.UPDATE_ASSET_INTERNAL_COMMENT,
        payload: comment
      });
    },
    1000,
    (msg) => {
      if (msg.payload.assetId !== asset?.id) return false;
      if (msg.payload.assetVersionId !== selectedVersion?.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'asset-comment-updated'
  );

  const modalState = useTypedSelector(({ modal }) => modal.approvalModal);
  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    async (messages) => {
      if (!reviewId) return;
      const newVersionId: string | undefined = messages
        .reverse()
        .find((x) => x.payload.reason === 'new-version')
        ?.payload.assetVersionId;
      let reloadCurrentVersion = !newVersionId;
      if (newVersionId) {
        try {
          const {
            data: { asset }
          } = await approvalWorkflowGetAssetByAw({
            assetVersionId: newVersionId,
            password
          });
          dispatch(setAsset(asset));
          dispatch(setAssetVariant(newVersionId));
          if (modalState && modalState.visible) {
            dispatch(toggleApprovalModal({ ...modalState, visible: false }));
          }
          navigate(`/approval?reviewId=${newVersionId}`, { replace: true });
        } catch {
          reloadCurrentVersion = true;
        }
      }
      if (reloadCurrentVersion) {
        try {
          const {
            data: { asset }
          } = await approvalWorkflowGetAssetByAw({
            assetVersionId: reviewId,
            password
          });
          dispatch(setAsset(asset));
          dispatch(setAssetVariant(reviewId));
          if (modalState && modalState.visible) {
            const assetVersion = asset.asset?.versions.find(
              (x) => x.id === reviewId
            );
            if (!assetVersion)
              dispatch(toggleApprovalModal({ ...modalState, visible: false }));
            else if (!modalState.isEditMode) {
              dispatch(
                toggleApprovalModal({
                  ...modalState,
                  assetVersions: [assetVersion],
                  isLastVersion:
                    assetVersion.versionNumber === asset.asset?.versionsCount
                })
              );
            }
          }
        } catch {
          window.location.reload();
        }
      }
    },
    1000,
    (msg) => {
      if (msg.payload.assetId !== asset?.id) return false;
      if (
        msg.causedByMe &&
        msg.payload.reason !== 'conversion-status-changed' &&
        msg.payload.reason !== 'new-version'
      )
        return false;

      return true;
    },
    'asset-updated'
  );

  if (!user || !selectedVersion) return null;

  const aw = selectedVersion.approvalWorkflow;
  if (!aw)
    return (
      <ApprovalReviewerPage
        element={<AccessClose type="approval" awReason="not-a-reviewer" />}
      />
    );

  const currentStageIndex =
    aw.stages.findIndex((x) => x.id === aw.currentStageId) ?? -1;
  const isManager = selectedVersion.permissions.manageApprovalWorkflow;
  if (!isManager) {
    const isReviewerOnCurrenOrPrevStages = aw.stages.some(
      (stage, i) =>
        i <= currentStageIndex && stage.members.some((member) => member.me)
    );
    const isReviewerOnNextStages = aw.stages.some(
      (stage, i) =>
        i > currentStageIndex && stage.members.some((member) => member.me)
    );
    const versionsCount = asset?.asset?.versionsCount ?? 0;
    const newVersionAvailable =
      selectedVersion && versionsCount > selectedVersion.versionNumber;

    const accessCloseReason = aw.isPaused
      ? newVersionAvailable
        ? 'new-version-available'
        : 'paused'
      : !isReviewerOnCurrenOrPrevStages
      ? isReviewerOnNextStages
        ? 'stage-not-started'
        : 'not-a-reviewer'
      : undefined;

    if (accessCloseReason)
      return (
        <ApprovalReviewerPage
          element={<AccessClose type="approval" awReason={accessCloseReason} />}
        />
      );
  }

  return (
    <Layout
      style={{ background: '#1A1C1E', color: '#fff', minHeight: '100vh' }}
    >
      <Layout
        className="site-layout external-reviewer-media"
        style={{ background: '#1A1C1E' }}
      >
        <Header
          className="site-layout-background b-background-black"
          style={{
            padding: 0,
            width: '100%'
          }}
        >
          <MediaViewerHeader
            isExternalReviewer={true}
            password={password}
            batchId={selectedVersion.approvalWorkflow?.batchId || ''}
          />
        </Header>
        <Content style={{ marginTop: 70 }}>
          <Outlet context={outletContext} />
        </Content>
      </Layout>
    </Layout>
  );
}

function ApprovalReviewerBatchAssetsList() {
  const { password, batchId } = useAssetReview();
  return (
    <Layout
      style={{ background: '#1A1C1E', color: '#fff', minHeight: '100vh' }}
    >
      <Layout
        className="site-layout external-reviewer-media"
        style={{ background: '#1A1C1E' }}
      >
        <Header
          className="site-layout-background b-background-black"
          style={{
            padding: 0,
            width: '100%'
          }}
        >
          <MediaViewerHeader
            isBatchAssetsList={true}
            password={password}
            batchId={batchId || ''}
          />
        </Header>
        <Content style={{ marginTop: 70 }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default ApprovalReviewerMediaLayout;
