import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { ReactComponent as PrivateEye } from '@assets/icons/eye-private.svg';
import { Dropdown, Menu, Button, message } from 'antd';

import PageSortPanel from '@components/PageSortPanel';
import { useCurrentWorkspace } from '@hooks/workspace';
import { usePrevious, useTypedSelector } from '@hooks';
import {
  setCurrentCampaign,
  updateCampaignAction,
  updateCampaignCounters
} from '@redux/actions/campaignAction';
import { getCampaign } from '@api/Campaign';
import { assetGetAssetsDownloadList, getAsset } from '@api/Asset';
import './FolderItem.less';
import {
  addAsset,
  loadMoreAssets,
  setAssetList,
  setSelectedFolder,
  updateAsset,
  updateFolderCounters,
  updateFolders
} from '@redux/actions/assetsAction';
import FolderEmptyState from '@pages/Campaigns/FolderEmptyState';
import { AssetsList } from '@components/Assets';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AssetsActionTypes } from '@redux/types/assetsType';
import {
  toggleApprovalConflictAssetsModal,
  toggleChangeFolderAccessModal,
  toggleEntityDeletionModal,
  toggleManageFolderAccessModal,
  toggleRenameEntityModal,
  toggleUploadAssetModal
} from '@redux/actions/modalAction';
import { AssetItemDto } from '@api/Api';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription
} from '@hooks/useWebSocketClient';
import {
  setWorkspaceCounters,
  updateAssetsWorkspaceCounters,
  updateCampaignsWorkspaceCounters
} from '@redux/actions/workspaceCountersAction';
import { useLayout } from '@layouts/PageLayout';
import {
  EntityAccessHasBeenChanged,
  EntityHasBeenRemoved
} from '@components/Toasts';
import { toast } from 'react-toastify';
import { useOrganization } from '@components/OrganizationBoundary';
import { downloadAll } from '@helpers/download';
import { OnboardingProcessContext } from '@context/OnboardingProcessProvider';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';
import { ReactComponent as ApprovalRoutingSvg } from '@assets/icons/approval-routing.svg';

function FolderItemInWorkspace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const folder = useTypedSelector(({ assets }) => assets.selectedFolder);
  const assetData = useTypedSelector(({ assets }) => assets.files);
  const campaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const { currentView } = useLayout();
  const { currentOrganization } = useOrganization();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [countToFetchFiles, setCountToFetchFiles] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string>('createdAt:ASC');
  const [listLoading, setListLoading] = useState<boolean>(true);

  const { onStartTour } = useContext(OnboardingProcessContext);

  const [searchParams] = useSearchParams();
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  useWebSocketSubscription((msg) => {
    if (!folder) return;
    if (msg.causedByMe) return;
    dispatch(updateCampaignCounters('deleteFolder'));
    if (msg.payload.folderId !== folder.id) return;
    toast(<EntityHasBeenRemoved entity="folder" name={folder.folder?.name} />, {
      hideProgressBar: true,
      style: { height: 66, width: 420 }
    });

    navigate(`../`);
  }, 'folder-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.folderId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { folderId, newName } = lastMessage.payload;
      dispatch({
        type: AssetsActionTypes.RENAME_FOLDER,
        payload: { folderId, name: newName }
      });
    },
    1000,
    (msg) => {
      if (!folder || msg.payload.folderId !== folder.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'folder-updated'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.folderId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { reason } = lastMessage.payload;
      try {
        const {
          data: { asset }
        } = await getAsset({
          assetId: lastMessage.payload.folderId,
          isFolder: true
        });
        if (reason === 'maybe-lost-access') {
          dispatch(updateFolders(asset));
          return;
        }
        dispatch(updateCampaignCounters('addFolder'));
        dispatch({
          type: AssetsActionTypes.CREATE_FOLDER,
          payload: { folder: asset }
        });
      } catch (e) {
        if (reason === 'maybe-lost-access') {
          toast(<EntityAccessHasBeenChanged entity="folder" />, {
            hideProgressBar: true,
            style: { height: 66, width: 420 }
          });
          dispatch(updateCampaignCounters('deleteFolder'));
          navigate(`../`);
        }
      }
    },
    1000,
    (msg) => {
      if (!folder || msg.payload.folderId !== folder.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'folder-access-changed'
  );

  useWebSocketSubscription((msg) => {
    if (!campaign || msg.payload.campaignId !== campaign.id) return;
    if (msg.causedByMe) return;
    dispatch(updateCampaignsWorkspaceCounters('delete', campaign));
    toast(<EntityHasBeenRemoved entity="campaign" name={campaign?.name} />, {
      hideProgressBar: true,
      style: { height: 66, width: 420 }
    });
    navigate(`../`);
    dispatch(setCurrentCampaign(null));
  }, 'campaign-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.campaignId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const {
        data: { campaign }
      } = await getCampaign({
        campaignId: lastMessage.payload.campaignId
      });

      dispatch(updateCampaignAction(campaign));
    },
    1000,
    (msg) => {
      if (!campaign || msg.payload.campaignId !== campaign.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'campaign-updated'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.campaignId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { campaignId, reason } = lastMessage.payload;
      try {
        const {
          data: { campaign }
        } = await getCampaign({
          campaignId
        });
        if (reason === 'maybe-lost-access') {
          dispatch(updateCampaignAction(campaign));
          return;
        }
      } catch (e) {
        if (reason === 'maybe-lost-access') {
          toast(<EntityAccessHasBeenChanged entity="campaign" />, {
            hideProgressBar: true,
            style: { height: 66, width: 420 }
          });
          dispatch(updateCampaignsWorkspaceCounters('lostAccess', campaign));
          navigate(`../`);
        }
      }
    },
    1000,
    (msg) => {
      if (!campaign || msg.payload.campaignId !== campaign.id) return false;
      if (msg.causedByMe) return false;
      return true;
    },
    'campaign-access-changed'
  );

  useWebSocketSubscription(async (msg) => {
    if (!folder) return;
    if (msg.causedByMe && msg.payload.reason !== 'upload') return;

    const {
      data: { asset }
    } = await getAsset({ assetId: msg.payload.assetId });
    const isRestoring = msg.payload.reason === 'restored-from-recycle-bin';
    dispatch(
      updateAssetsWorkspaceCounters(isRestoring ? 'restore' : 'create', asset)
    );

    dispatch(updateFolderCounters('addFile', folder.id));
    dispatch(updateCampaignCounters('addFile', msg.payload.folderId));

    if (msg.payload.folderId !== folder.id) return;
    dispatch(addAsset(msg.payload.assetId));
  }, 'asset-created');

  useWebSocketSubscription(async (msg) => {
    if (!folder || msg.payload.folderId !== folder.id) return;
    if (msg.causedByMe) return;
    if (
      msg.payload.reason === 'added-to-stack' ||
      msg.payload.reason === 'moved-to-recycle-bin'
    )
      dispatch(updateCampaignCounters('deleteFile', msg.payload.folderId));
    const isAssetRemovedFromDBList =
      msg.payload.reason === 'deleted-permanently' ||
      msg.payload.reason === 'added-to-stack';
    if (isAssetRemovedFromDBList) {
      const isPermanent = msg.payload.reason === 'deleted-permanently';
      const asset = assetData.edges?.find(
        (el) => el.node.id === msg.payload.assetId
      )?.node;
      if (asset)
        dispatch(
          updateAssetsWorkspaceCounters(
            isPermanent ? 'deletePermanent' : 'addToStack',
            asset
          )
        );
      else dispatch(setWorkspaceCounters(msg.payload.workspaceId));

      dispatch({
        type: AssetsActionTypes.DELETE_ASSET,
        payload: {
          assetId: msg.payload.assetId,
          folderId: msg.payload.folderId
        }
      });
      return;
    }
    const {
      data: { asset }
    } = await getAsset({
      assetId: msg.payload.assetId,
      isDeleted: true
    });
    dispatch(updateAssetsWorkspaceCounters('delete', asset));
    dispatch({
      type: AssetsActionTypes.DELETE_ASSET,
      payload: { assetId: msg.payload.assetId, folderId: msg.payload.folderId }
    });
  }, 'asset-removed');

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.assetId,
    (messages) => {
      const lastMessage = messages[messages.length - 1];
      dispatch(updateAsset(lastMessage.payload.assetId));
    },
    1000,
    (msg) => {
      if (!folder || msg.payload.folderId !== folder.id) return false;
      if (msg.causedByMe && msg.payload.reason !== 'conversion-status-changed')
        return false;
      return true;
    },
    'asset-updated'
  );

  useEffect(() => {
    if (!campaign) return;
    (async () => {
      setListLoading(true);
      await setAssetList({
        workspaceId: campaign.workspaceId,
        flatten: false,
        parentId: folder?.id,
        isFolder: false
      })(dispatch);
      setListLoading(false);
    })();
    return () => {
      dispatch({
        type: AssetsActionTypes.SET_ASSETS_LIST,
        payload: { edges: null, count: null }
      });
      dispatch({
        type: AssetsActionTypes.SET_FOLDER,
        payload: { folder: null }
      });
    };
  }, [campaign?.id]);

  useEffect(() => {
    if (listLoading) return;
    const startTour = searchParamsRef.current.get('startTour') ?? undefined;
    if (startTour === 'upload-asset') {
      setTimeout(() => {
        onStartTour({ type: startTour as any });
      }, 1000);
    }
  }, [onStartTour, listLoading]);

  const loadMore = async () => {
    if (!campaign) return;
    const params = {
      searchQuery,
      workspaceId: campaign.workspaceId,
      campaignId: campaign.id,
      orderBy: [orderBy as any],
      isFolder: false,
      flatten: false,
      parentId: folder?.id,
      after: assetData.endCursor
    };
    const count = Math.min(
      (assetData?.count || 0) - (assetData?.edges?.length || 0),
      25
    );
    setCountToFetchFiles(count);

    await loadMoreAssets(params)(dispatch);
    setCountToFetchFiles(0);
  };

  const upgradePrivateFolders =
    !currentOrganization.entity?.features.privateFolders;

  return (
    <div>
      <div className="header-container">
        <div className="title-container">
          <span className="folder-name">
            {folder?.isPrivate && (
              <div>
                <PrivateEye />
              </div>
            )}
            <div>{folder?.folder?.name}</div>
          </span>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="Download files"
                  disabled={folder?.folder?.assetsCount === 0}
                  onClick={async () => {
                    if (!folder?.folder) return;
                    const {
                      data: { list }
                    } = await assetGetAssetsDownloadList({
                      campaignId: folder.campaignId,
                      workspaceId: folder.workspaceId,
                      parentId: folder.id
                    });
                    downloadAll(
                      list.map((x) => x.sourceUrl),
                      folder.folder.name
                    );
                  }}
                >
                  Download files
                </Menu.Item>
                {folder?.folder?.permissions.setVisibility && (
                  <Menu.Item
                    key="Toggle access"
                    disabled={!folder.isPrivate && upgradePrivateFolders}
                    onClick={() => {
                      if (!folder.isPrivate && upgradePrivateFolders) return;
                      if (!folder?.folder) return;
                      dispatch(
                        toggleChangeFolderAccessModal({
                          folder,
                          visible: true,
                          callback: async (folder: AssetItemDto) => {
                            if (!folder.isPrivate) return;
                            dispatch(
                              toggleManageFolderAccessModal({
                                folder,
                                visible: true
                              })
                            );
                          }
                        })
                      );
                    }}
                  >
                    <UpgradePlanTooltip
                      text=" to a Team plan to access this feature."
                      isCanVisible={!folder.isPrivate && upgradePrivateFolders}
                      placement="right"
                      innerStyle={{
                        transform: 'translate(-30px, 0)'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '4px',
                          alignItems: 'center'
                        }}
                      >
                        {folder?.isPrivate ? 'Make public' : 'Make private'}

                        {!folder.isPrivate && upgradePrivateFolders && (
                          <UpgradePlanSvg className="upgrade-icon" />
                        )}
                      </div>
                    </UpgradePlanTooltip>
                  </Menu.Item>
                )}
                {folder?.folder?.permissions.rename && (
                  <Menu.Item
                    key="Rename"
                    onClick={() => {
                      dispatch(
                        toggleRenameEntityModal({
                          entity: folder as AssetItemDto,
                          page: 'folder',
                          visible: true
                        })
                      );
                    }}
                  >
                    Rename
                  </Menu.Item>
                )}
                {(folder?.folder?.permissions?.inviteContributors ||
                  folder?.folder?.permissions?.inviteFromList) && (
                  <Menu.Item
                    key="Manage users"
                    onClick={() => {
                      if (!folder) return;
                      dispatch(
                        toggleManageFolderAccessModal({ folder, visible: true })
                      );
                    }}
                  >
                    Manage users
                  </Menu.Item>
                )}

                {folder?.folder?.permissions.delete && (
                  <Menu.Item
                    key="delete"
                    disabled={!folder?.folder?.permissions.delete}
                    onClick={() => {
                      if (!folder?.folder?.permissions.delete) return;
                      dispatch(
                        toggleEntityDeletionModal({
                          entity: folder as AssetItemDto,
                          page: 'folder',
                          permanent: true,
                          visible: true,
                          func: () => navigate(`../`)
                        })
                      );
                    }}
                  >
                    Delete
                  </Menu.Item>
                )}
              </Menu>
            }
            overlayClassName="asset_links_overlay"
            placement="bottomLeft"
            trigger={['click']}
          >
            <Button
              className="header-container__dropdown-trigger"
              type="text"
              size="small"
            >
              <ActionSvg style={{ marginRight: 0 }} />
            </Button>
          </Dropdown>
        </div>
        <Button
          type="default"
          size="small"
          className="info-button"
          onClick={() => {
            dispatch(
              toggleApprovalConflictAssetsModal({
                visible: true,
                title: 'Route folder for approval',
                campaignId: folder?.campaignId,
                workspaceId: folder?.workspaceId,
                parentId: folder?.id,
                callback: () => {
                  if (!campaign) return;
                  setAssetList({
                    workspaceId: campaign.workspaceId,
                    flatten: false,
                    parentId: folder?.id,
                    isFolder: false,
                    ...(searchQuery ? { searchQuery } : {}),
                    ...(orderBy ? { orderBy: [orderBy as any] } : {})
                  })(dispatch);
                }
              })
            );
          }}
        >
          <ApprovalRoutingSvg />
          <span>Create approval routing</span>
        </Button>
      </div>
      <PageSortPanel
        entity="in folder"
        onAddClick={
          campaign?.demo
            ? undefined
            : () => {
                if (!campaign) return;
                dispatch(
                  toggleUploadAssetModal({
                    visible: true,
                    workspaceId: campaign.workspaceId
                  })
                );
              }
        }
        actionButtonName="Upload new media"
        onSearch={async (e: any) => {
          setSearchQuery(e);
          if (!campaign) return;
          setListLoading(true);
          const params = {
            searchQuery: e,
            workspaceId: campaign.workspaceId,
            campaignId: campaign.id,
            orderBy: [orderBy as any],
            isFolder: false,
            flatten: false,
            parentId: folder?.id
          };
          await setAssetList(params)(dispatch);
          setListLoading(false);
        }}
        onOrderBy={undefined}
        classNameSearchContainer={undefined}
        classNameActionButton={undefined}
        sortMenuModal={
          <Menu
            selectedKeys={[orderBy]}
            onClick={async ({ key }) => {
              setOrderBy(key);
              if (!campaign) return;
              setListLoading(true);
              const params = {
                searchQuery,
                workspaceId: campaign.workspaceId,
                campaignId: campaign.id,
                orderBy: [key as any],
                isFolder: false,
                flatten: false,
                parentId: folder?.id
              };
              await setAssetList(params)(dispatch);
              setListLoading(false);
            }}
          >
            <span className="help_dropdown__title">Sort by</span>
            <Menu.Item key="order:DESC">
              <span className="help_dropdown__dot" /> Custom
            </Menu.Item>
            <Menu.Item key="createdAt:DESC">
              <span className="help_dropdown__dot" /> Date created
            </Menu.Item>
            <Menu.Item key="status:ASC">
              <span className="help_dropdown__dot" /> Status
            </Menu.Item>
            <Menu.Item key="lastEventTime:DESC">
              <span className="help_dropdown__dot" /> Date modified
            </Menu.Item>
          </Menu>
        }
        searchInputComponent={undefined}
        classNamePanel={undefined}
      />
      <div className="menu-panel-container">
        {(folder?.folder?.assetsCount || 0) > 0 ? (
          <InfiniteScroll
            dataLength={assetData.edges?.length || 0}
            next={loadMore}
            hasMore={assetData.hasNext || false}
            loader={<></>}
            height="calc(100vh - 260px)"
            style={{
              overflowX: 'hidden',
              marginTop: 24,
              paddingRight: 34,
              paddingLeft: 34,
              marginRight: -34,
              marginLeft: -34
            }}
          >
            <AssetsList
              searchQuery={searchQuery}
              assetsData={assetData}
              displayAssetsCount={folder?.folder?.assetsCount || 0}
              isTileView={currentView === 'tile'}
              page="campaign"
              canCombine={true}
              countToFetch={countToFetchFiles}
              listLoading={listLoading}
              canReorganize={orderBy === 'order:DESC'}
              isShowCompany={false}
            />
          </InfiniteScroll>
        ) : (
          <FolderEmptyState />
        )}
      </div>
    </div>
  );
}

function FolderItem(props: any) {
  const { campaignId, folderId } = useParams();
  const dispatch = useDispatch();
  const [currentWorkspace, setCurrentWorkspace] = useCurrentWorkspace(false);
  const { currentOrganization } = useOrganization();

  const prevWsPermissions = usePrevious(currentWorkspace?.permissions);
  const prevOrgPermissions = usePrevious(
    currentOrganization.entity?.permissions
  );
  const campaign = useTypedSelector(
    ({ campaign }) => campaign.selectedCampaign
  );
  const folder = useTypedSelector(({ assets }) => assets.selectedFolder);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentOrganization.entity?.permissions || !prevOrgPermissions) return;
    const newOrgPermission = JSON.stringify(
      currentOrganization.entity?.permissions
    );
    const oldOrgPermission = JSON.stringify(prevOrgPermissions);
    if (newOrgPermission === '{}' || oldOrgPermission === '{}') return;
    if (newOrgPermission === oldOrgPermission) return;
    (async () => {
      try {
        const result = await getAsset({
          assetId: folderId || '',
          isFolder: true
        });
        const { asset } = result.data;
        dispatch(setSelectedFolder(asset));
      } catch (e) {
        navigate('../');
      }
    })();
  }, [
    currentOrganization.entity?.permissions,
    dispatch,
    folderId,
    navigate,
    prevOrgPermissions
  ]);

  useEffect(() => {
    if (!currentWorkspace?.permissions || !prevWsPermissions) return;
    const newWsPermission = JSON.stringify(currentWorkspace.permissions);
    const oldWsPermission = JSON.stringify(prevWsPermissions);
    if (newWsPermission === '{}' || oldWsPermission === '{}') return;
    if (newWsPermission === oldWsPermission) return;
    (async () => {
      try {
        const result = await getAsset({
          assetId: folderId || '',
          isFolder: true
        });
        const { asset } = result.data;
        dispatch(setSelectedFolder(asset));
      } catch (e) {
        navigate('../');
      }
    })();
  }, [
    currentWorkspace?.permissions,
    dispatch,
    folderId,
    navigate,
    prevWsPermissions
  ]);

  useEffect(() => {
    if (!campaignId || campaignId === campaign?.id) return;
    (async () => {
      try {
        const result = await getCampaign({ campaignId: campaignId || '' });
        const { campaign } = result.data;
        dispatch(setCurrentCampaign(campaign));
      } catch (e) {
        message.error('This folder is no longer available.');
        navigate('../');
      }
    })();
  }, [campaignId, campaign, dispatch]);

  useEffect(() => {
    if (!folderId || folderId === folder?.id) return;
    (async () => {
      try {
        const result = await getAsset({ assetId: folderId, isFolder: true });
        const { asset } = result.data;
        dispatch(setSelectedFolder(asset));
      } catch (e) {
        message.error('This folder is no longer available.');
        navigate('../');
      }
    })();
  }, [folderId, folder, dispatch]);
  useEffect(() => {
    if (campaign && campaign.workspaceId !== currentWorkspace?.id) {
      setCurrentWorkspace(campaign.workspaceId);
    }
  }, [campaign, currentWorkspace, setCurrentWorkspace]);
  if (!folder || !campaign || !currentWorkspace) return null;
  if (campaign.workspaceId !== currentWorkspace.id) return null;
  if (folder.workspaceId !== currentWorkspace.id) return null;
  return <FolderItemInWorkspace {...props} />;
}

export default FolderItem;
