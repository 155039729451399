import { KnockFeedProvider } from '@components/NotificationFeed/KnockFeedProvider';
import {
  NotificationIconButton,
  NotificationFeedPopover,
  NotificationCell
} from '@knocklabs/react';
import React, { ReactComponentElement, useRef, useState } from 'react';
import '@knocklabs/react/dist/index.css';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '@components/UserAvatar';
import { previewThumbnailsByType } from '@helpers/assetPreviewThumbnails';
import './Notification.less';

export function NotificationFeed() {
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  return (
    <KnockFeedProvider>
      <div className="notification-feed">
        <NotificationIconButton
          ref={buttonRef}
          onClick={() => setIsVisible(!isVisible)}
          badgeCountType="unseen"
        />
        <NotificationFeedPopover
          buttonRef={buttonRef}
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          onNotificationClick={(item) => {
            const actionUrl = item.blocks
              .filter((x) => x.name === 'action_url')
              .filter((x) => x.type === 'text')
              .map((x) => new URL(x.rendered))
              .find(() => true);
            if (!actionUrl) return;
            if (actionUrl.host === window.location.host) {
              navigate(actionUrl.pathname + actionUrl.search);
            } else {
              window.open(actionUrl, '_blank')?.focus();
            }
          }}
          renderItem={({ item, ...props }) => {
            let avatar: React.ReactNode | undefined;
            if (item.actors.length) {
              const actor = item.actors[0];
              avatar = (
                <UserAvatar
                  size="large"
                  isActive
                  src={actor.avatar}
                  userName={actor.name}
                  userEmail={actor.email}
                  style={{ flexShrink: 0 }}
                />
              );
            }
            let image: string | ReactComponentElement<any> | undefined;
            if (!image && item.data?.asset_image) {
              image = item.data.asset_image;
            }
            if (!image && item.data?.asset_type) {
              image = previewThumbnailsByType(item.data.asset_type);
            }
            return (
              <NotificationCell
                key={item.id}
                {...props}
                item={item}
                avatar={avatar}
              >
                {image && (
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 80,
                      height: 56,
                      borderRadius: 6,
                      overflow: 'hidden'
                    }}
                  >
                    {typeof image === 'string' && !!image ? (
                      <img
                        alt="notification preview"
                        src={image}
                        style={{
                          width: '100%',
                          maxWidth: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    ) : (
                      !!image && image
                    )}
                  </div>
                )}
              </NotificationCell>
            );
          }}
        />
      </div>
    </KnockFeedProvider>
  );
}
