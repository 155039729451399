import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import Loader from '@components/Loader';
import { AssetItemDto } from '@api/Api';
import { formatDuration } from '@helpers/formatDuration';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import Status from '@components/Approval/Status';

import './AssetCard.less';

type AssetCardProps = {
  asset: AssetItemDto;
};

export default function AssetCard({ asset }: AssetCardProps) {
  const scrubRef = useRef<any>();
  const scrubLineRef = useRef<any>();
  const [scrubsVisible, setScrubsVisible] = useState<boolean>(false);
  const [scrubsUrl, setScrubsUrl] = useState<string>('');
  const [frameWidth, setFrameWidth] = useState<number>(0);
  const [frameHeight, setFrameHeight] = useState<number>(0);
  const [framesCount, setFramesCount] = useState<number>(0);

  useEffect(() => {
    if (asset?.asset?.versions[0].metadata.type === 'video') {
      setScrubsUrl(asset.asset?.versions[0].metadata.scrub.url || '');
      setFrameWidth(
        asset.asset?.versions[0].metadata.scrub.frames?.dimensions.width || 0
      );
      setFrameHeight(
        asset.asset?.versions[0].metadata.scrub.frames?.dimensions.height || 0
      );
      setFramesCount(
        asset.asset?.versions[0].metadata.scrub.frames?.count || 0
      );
    }
  }, [asset]);
  const onMouseEnter = useCallback(() => {
    if (asset?.asset?.versions[0].metadata.type !== 'video') return;
    setScrubsVisible(true);
    scrubLineRef.current.style.display = 'block';
  }, [setScrubsVisible, asset]);

  const onMouseLeave = useCallback(() => {
    if (asset?.asset?.versions[0].metadata.type !== 'video') return;
    setScrubsVisible(false);
    scrubLineRef.current.style.display = 'none';
  }, [setScrubsVisible, asset]);

  const onMouseMove = useCallback(
    async (e) => {
      if (asset?.asset?.versions[0].metadata.type !== 'video') return;
      if (asset?.asset?.versions[0].metadata.preview.status === 'pending')
        return;
      const canvasEl = scrubRef.current;
      if (!canvasEl) return;
      const rect = e.target.getBoundingClientRect();
      const stepWidth = rect.width / framesCount;
      const frameIndex = Math.floor((e.clientX - rect.left) / stepWidth);
      const img = new Image();
      canvasEl.frameIndex = frameIndex;
      img.src = scrubsUrl;
      img.onload = () => {
        const canvasEl = scrubRef.current;
        if (!canvasEl || canvasEl.frameIndex !== frameIndex) return;

        if (scrubLineRef?.current) {
          scrubLineRef.current.style.left = `${Math.floor(
            e.clientX - rect.left
          )}px`;
        }
        const ctx = scrubRef.current.getContext('2d');
        ctx.drawImage(
          img,
          0,
          frameIndex * frameHeight,
          frameWidth,
          frameHeight,
          0,
          0,
          frameWidth,
          frameHeight
        );
      };
    },
    [
      scrubsUrl,
      frameWidth,
      frameHeight,
      scrubLineRef,
      framesCount,
      scrubRef,
      asset
    ]
  );

  const preparePreview = useMemo(() => {
    const res = assetPreviewThumbnails({
      url: asset.asset?.versions[0].metadata.preview?.url || '',
      status: asset.asset?.versions[0].metadata.preview?.status || '',
      type: asset.asset?.versions[0].metadata.type || '',
      format: 'img'
    });

    if (res === 'pending') return 'pending';
    if (typeof res === 'string')
      return <img draggable={false} src={res || ''} alt="" />;
    return res;
  }, [asset.asset?.versions]);

  return (
    <div className="asset-card">
      <div className="asset-card_preview">
        {preparePreview === 'pending' && (
          <Loader
            classNameWrapper="asset-card_preview-loader"
            spinSize={40}
            isSpinning={true}
          />
        )}
        <div
          className="asset-card_image"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
        >
          {preparePreview !== 'pending' && preparePreview}
          {scrubsVisible && (
            <canvas
              ref={scrubRef}
              width={frameWidth}
              height={frameHeight}
              className="asset-card_image-canvas"
            />
          )}
          <div ref={scrubLineRef} className="asset-card_image-line" />
        </div>
        <div className="asset-card_preview-info">
          <div className="asset-card_preview-info-left">
            {!!asset.asset?.versions[0].approvalWorkflow && (
              <Status
                approvalWorkflow={asset.asset.versions[0].approvalWorkflow}
              />
            )}
          </div>
          {(asset.asset?.versions[0].metadata.type === 'audio' ||
            asset.asset?.versions[0].metadata.type === 'video') && (
            <div className="asset-card_preview-info-duration">
              {formatDuration(
                asset.asset?.versions[0].metadata.info.avInfo?.audio
                  ?.durationSeconds ||
                  asset.asset?.versions[0].metadata.info.avInfo?.video
                    ?.durationSeconds ||
                  0
              )}
            </div>
          )}
        </div>
      </div>
      <div className="asset-card_content">
        <Tooltip
          placement="topLeft"
          overlayClassName="asset-card_tooltip"
          title={`${asset?.asset?.versions[0]?.name}.
              ${asset?.asset?.versions[0]?.extension}`}
        >
          <div className="asset-card_name">
            {asset?.asset?.versions[0]?.name}
          </div>
        </Tooltip>
        <div className="asset-card_date">
          Edited {moment(asset.modifiedAt).fromNow()}
        </div>
      </div>
    </div>
  );
}
