import React, { useState } from 'react';
import DropdownMenuButton from '@components/EntitiesDropdownMenu/DropdownMenuButton';
import { Dropdown, Menu } from 'antd';

import './EntitiesDropdownMenu.less';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useDispatch } from 'react-redux';

import { AssetForApprovalDto } from '@api/Api';
import { toggleApprovalModal } from '@redux/actions/modalAction';
import { updateApprovalWorkflow } from '@api/Approval';

type ApprovalMenuProps = {
  approval: AssetForApprovalDto;
  setIsDropDownOpen: (v: boolean) => void;
  setApprovalsCount: any;
  setApprovals: any;
};

export function ApprovalMenu({
  approval,
  setIsDropDownOpen,
  setApprovalsCount,
  setApprovals
}: ApprovalMenuProps) {
  const dispatch = useDispatch();
  const assetId = approval.id;
  const assetVersionId = approval.asset.versions[0].id;
  const isPaused = !!approval.asset.versions[0].approvalWorkflow?.isPaused;
  const canManage =
    approval.asset.versions[0].permissions.manageApprovalWorkflow;

  const items: ItemType[] = [
    {
      key: 'edit_approval',
      label: canManage ? 'Edit' : 'View',
      onClick: () => {
        dispatch(
          toggleApprovalModal({
            assetIds: [approval.id],
            campaignId: approval.campaignId,
            allowCreate: !!approval.asset?.permissions.createApprovalWorkflows,
            assetVersions: [approval.asset.versions[0]],
            isLastVersion: true,
            visible: true,
            openFormCampaignPage: true,
            callback: (asset) => {
              const approvalDeleted =
                !!approval.asset.versions[0].approvalWorkflow &&
                !asset.asset?.versions[0].approvalWorkflow;
              if (approvalDeleted) {
                setApprovalsCount((prev: number): number => {
                  return prev - 1;
                });
                setApprovals((prev: any) => ({
                  ...prev,
                  edges: prev.edges.filter((x: any) => x.node.id !== asset.id)
                }));
              } else {
                setApprovals((prev: any) => ({
                  ...prev,
                  edges: prev.edges.map((x: any) => {
                    if (x.node.id === asset.id) return { ...x, node: asset };
                    return x;
                  })
                }));
              }
            }
          })
        );
      }
    },
    {
      key: 'pauseResume_approval',
      label: isPaused ? 'Resume' : 'Pause',
      onClick: async () => {
        const {
          data: { asset }
        } = await updateApprovalWorkflow({
          assetId,
          assetVersionId,
          isPaused: !isPaused
        });
        setApprovals((prev: any) => ({
          ...prev,
          edges: prev.edges.map((el: any) => {
            if (el.node.id === asset.id) return { ...el, node: asset };

            return el;
          })
        }));
      }
    }
  ];
  return (
    <Menu
      className="entities-dropdown-menu-overlay"
      items={items}
      onClick={(e) => {
        e.domEvent.stopPropagation();
        setIsDropDownOpen(false);
      }}
    />
  );
}

function ApprovalDropDownMenu({
  approval,
  setApprovalsCount,
  setApprovals
}: {
  approval: AssetForApprovalDto;
  setApprovalsCount: any;
  setApprovals: any;
}) {
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

  return (
    <Dropdown
      trigger={['click']}
      onOpenChange={(value) => setIsDropDownOpen(value)}
      open={isDropDownOpen}
      overlay={
        <ApprovalMenu
          setIsDropDownOpen={setIsDropDownOpen}
          approval={approval}
          setApprovalsCount={setApprovalsCount}
          setApprovals={setApprovals}
        />
      }
      overlayClassName="entities-dropdown-menu-overlay"
    >
      <DropdownMenuButton onOpenChange={setIsDropDownOpen} />
    </Dropdown>
  );
}

export default ApprovalDropDownMenu;
