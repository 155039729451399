import React, { useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Tooltip, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AWAssetListItemDto } from '@api/Api';
import { formatDuration } from '@helpers/formatDuration';
import { ReactComponent as LockSvg } from '@assets/icons/lock.svg';
import { ReactComponent as ArrowCheckSvg } from '@assets/icons/check-task.svg';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';

import './ApprovalAssetCard.less';

type ApprovalAssetCardProps = {
  asset: AWAssetListItemDto;
  isSelected: boolean;
  onSelect: (asset: AWAssetListItemDto) => void;
};

export default function ApprovalAssetCard({
  asset,
  isSelected,
  onSelect
}: ApprovalAssetCardProps) {
  const navigate = useNavigate();
  const decisionDisabled =
    asset?.isPaused ||
    asset.isFinished ||
    (asset.lockDecisions && asset.decision) ||
    !asset.canSubmitDecision;

  const decisionDisabledOpenAsset = asset.isPaused || asset.isFutureReview;

  const onOpenAsset = () => {
    if (decisionDisabledOpenAsset) return;
    navigate(`/approval?reviewId=${asset.versionId}`);
  };

  const preparePreview = useMemo(() => {
    const res = assetPreviewThumbnails({
      url: asset.previewUrl || '',
      status: 'ready',
      type: asset.type,
      format: 'img'
    });

    if (typeof res === 'string')
      return <img draggable={false} src={res || ''} alt="" />;
    return res;
  }, [asset]);

  return (
    <div
      className={classNames('approval-asset-card', {
        'approval-asset-card--disabled-open': decisionDisabledOpenAsset,
        'approval-asset-card--disabled': decisionDisabled || !!asset.decision
      })}
      onClick={onOpenAsset}
    >
      <div className="approval-asset-card_preview">
        {decisionDisabled && (
          <div className="approval-asset-card_lock-icon">
            <LockSvg />
          </div>
        )}
        {!decisionDisabled && (
          <Checkbox
            value={asset?.id}
            checked={isSelected}
            onChange={() => onSelect(asset)}
            onClick={(e) => e.stopPropagation()}
          />
        )}
        <div className="approval-asset-card_image">{preparePreview}</div>
        <div className="approval-asset-card_preview-info">
          <div className="approval-asset-card_preview-info-left">
            {!!asset.decision && (
              <div className="approval-asset-card_preview-info-status">
                <div className="approval-asset-card_preview-info-status-icon">
                  <ArrowCheckSvg />
                </div>
                Already reviewed
              </div>
            )}
          </div>
          {(asset.type === 'audio' || asset.type === 'video') && (
            <div className="approval-asset-card_preview-info-duration">
              {formatDuration(asset.durationSeconds || 0)}
            </div>
          )}
        </div>
      </div>
      <div className="approval-asset-card_content">
        <Tooltip
          placement="topLeft"
          overlayClassName="approval-asset-card_tooltip"
          title={`${asset.name}.
              ${asset.extension}`}
        >
          <div className="approval-asset-card_name">{asset.name}</div>
        </Tooltip>
        <div className="approval-asset-card_date">
          Edited {moment(asset.updatedAt).fromNow()}
        </div>
      </div>
    </div>
  );
}
