import {
  ExternalReviewerControllerGetReviewerPictureUploadParamsParams,
  SignInAsExternalAWBatchReviewerDto,
  SignInAsExternalAWReviewerDto,
  SignInAsExternalReviewerDto,
  SignUpAsExternalAWBatchReviewerDto,
  SignUpAsExternalAWReviewerDto,
  SignUpAsExternalReviewerDto,
  UpdateExternalReviewerDto
} from '@api/Api';
import { apiClient } from '@api/client';

export const signInExternalReviewer = (data: SignInAsExternalReviewerDto) =>
  apiClient.externalReviewer.externalReviewerControllerSignInAsExternalReviewer(
    data
  );

export const signUpExternalReviewer = (data: SignUpAsExternalReviewerDto) =>
  apiClient.externalReviewer.externalReviewerControllerSignUpAsExternalReviewer(
    data
  );

export const getExternalReviewer = () =>
  apiClient.externalReviewer.externalReviewerControllerGetExternalReviewer();

export const updateExternalReviewer = (data: UpdateExternalReviewerDto) =>
  apiClient.externalReviewer.externalReviewerControllerUpdateExternalReviewer(
    data
  );

export const uploadPictureExternalReviewer = (
  query: ExternalReviewerControllerGetReviewerPictureUploadParamsParams
) =>
  apiClient.externalReviewer.externalReviewerControllerGetReviewerPictureUploadParams(
    query
  );

export const deletePictureExternalReviewer = () =>
  apiClient.externalReviewer.externalReviewerControllerDeleteExternalReviewerPicture();

export const externalReviewerSignUpAsExternalAwReviewer = (
  params: SignUpAsExternalAWReviewerDto
) =>
  apiClient.externalReviewer.externalReviewerControllerSignUpAsExternalAwReviewer(
    params
  );

export const externalReviewerSignUpAsExternalAwBatchReviewer = (
  params: SignUpAsExternalAWBatchReviewerDto
) =>
  apiClient.externalReviewer.externalReviewerControllerSignUpAsExternalAwBatchReviewer(
    params
  );

export const externalReviewerSignInAsExternalAwReviewer = (
  params: SignInAsExternalAWReviewerDto
) =>
  apiClient.externalReviewer.externalReviewerControllerSignInAsExternalAwReviewer(
    params
  );

export const externalReviewerSignInAsExternalAwBatchReviewer = (
  params: SignInAsExternalAWBatchReviewerDto
) =>
  apiClient.externalReviewer.externalReviewerControllerSignInAsExternalAwBatchReviewer(
    params
  );
