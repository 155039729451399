import MediaViewer from '@pages/MediaViewer';
import React, { ReactElement, useMemo } from 'react';
import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';
import { Route, Routes } from 'react-router-dom';
import {
  AssetReviewProvider,
  useAssetReview
} from '@components/AssetReviewProvider';
import LinkProtected from '@pages/ExternalReviewer/LinkProtected';
import AccessClose from '@pages/ExternalReviewer/AccessClose';
import ApprovalReviewerMediaLayout from '@layouts/ApprovalReviewerMediaLayout';
import { useAuth } from '@hooks/useAuth';
import EnterEmail from '@pages/ExternalReviewer/EnterEmail/EnterEmail';
import UploadAssetModal from '@components/Modals/UploadAssetModal';
import ApprovalModal from '@components/Modals/ApprovalModal';
import { WebSocketClientProvider } from '@hooks/useWebSocketClient';
import '@pages/ExternalReviewer/ExternalReviewer.less';
import UploadingAssetsProcess from '@components/UploadingAssetsProcess';
import { AssetsUppyProvider } from '@context/AssetsUppyContext';
import { OrganizationProvider } from '@components/OrganizationBoundary';
import { useFetch } from '@hooks/useFetch';
import {
  organizationItemsListStateSelector,
  organizationListStateSelector
} from '@redux/selectors/organizations';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { fetchOrganizationsList } from '@redux/actions/organizations';
import { useTypedSelector } from '@hooks';
import ApprovalAssetsList from '@pages/ApprovalReviewer/ApprovalAssetsList';

interface ApprovalReviewerProps {
  element: ReactElement;
}

export function ApprovalReviewerPage({ element }: ApprovalReviewerProps) {
  return (
    <div className="external_reviewer_container">
      <div className="external_reviewer_top">
        <LogoSvg />
      </div>
      <div className="external_reviewer_content">{element}</div>
      <div className="external_reviewer_bottom">
        New to StreamWork? Create your <a href="/login">own account</a> and
        start collaborating.
      </div>
    </div>
  );
}

interface ApprovalReviewerContainerProps {
  loader: ReactElement;
}

function ApprovalReviewerContainer({ loader }: ApprovalReviewerContainerProps) {
  const {
    isLoading,
    password,
    batchId,
    passwordRequired,
    passwordDoesNotMatch,
    isDisabled,
    commentMentions,
    listAssetsInBatch,
    setPassword
  } = useAssetReview();
  const dispatch = useTypedDispatch();
  const { user, error } = useAuth();

  const organizations = useTypedSelector((state) =>
    organizationItemsListStateSelector(state)
  );

  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );

  const beforeViewPage = useMemo<'login' | 'password' | null>(() => {
    if (!user) return 'login';
    if (passwordRequired && passwordDoesNotMatch) return 'password';
    return null;
  }, [user, passwordRequired, passwordDoesNotMatch, isLoading]);

  const loading = useFetch({
    disabled: user?.type !== 'internal' || !user.emailVerified,
    key: `organizations`,
    selector: (state) => organizationListStateSelector(state).fetch,
    fetch: (fetchType) => dispatch(fetchOrganizationsList({ fetchType }))
  });

  if (isDisabled || error?.error === 'not_aw_reviewer') {
    return (
      <ApprovalReviewerPage
        element={<AccessClose type="approval" awReason="not-a-reviewer" />}
      />
    );
  }
  if (beforeViewPage === 'login') {
    return <ApprovalReviewerPage element={<EnterEmail />} />;
  }
  if (beforeViewPage === 'password') {
    return (
      <ApprovalReviewerPage
        element={
          <LinkProtected
            password={password}
            passwordDoesNotMatch={passwordDoesNotMatch}
            setPassword={setPassword}
            loading={isLoading}
          />
        }
      />
    );
  }

  if ((isLoading || loading) && !selectedVersion) {
    return loader;
  }

  return (
    <OrganizationProvider organizations={organizations}>
      <AssetsUppyProvider>
        <Routes>
          <Route
            path="/*"
            element={
              <ApprovalReviewerMediaLayout
                layoutType={batchId ? 'batch-assets-list' : 'asset'}
              />
            }
          >
            <Route
              index
              element={
                batchId ? (
                  <ApprovalAssetsList
                    initialData={listAssetsInBatch}
                    batchId={batchId}
                    password={password}
                  />
                ) : (
                  <MediaViewer
                    commentMentions={commentMentions}
                    isLoadingAsset={isLoading}
                  />
                )
              }
            />
          </Route>
        </Routes>
        <ApprovalModal />
        <UploadAssetModal />
        <UploadingAssetsProcess />
      </AssetsUppyProvider>
    </OrganizationProvider>
  );
}

export default function ApprovalReviewer(
  props: ApprovalReviewerContainerProps
) {
  const { isAuthenticated, isLoading } = useAuth();
  if (!isAuthenticated && isLoading) return props.loader;
  return (
    <WebSocketClientProvider>
      <AssetReviewProvider type="approval">
        <ApprovalReviewerContainer {...props} />
      </AssetReviewProvider>
    </WebSocketClientProvider>
  );
}
