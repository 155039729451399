import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useTypedSelector } from '@hooks';
import { AWAssetListItemDto } from '@api/Api';
import { useAWListAssetsToReviewInBatch } from '@hooks/queries/approval';
import { ReactComponent as ArrowRightSvg } from '@assets/icons/arrow-right-without-line.svg';
import { ReactComponent as ArrowLeftSvg } from '@assets/icons/arrow-left-without-line.svg';
import { ReactComponent as ArrowCheckSvg } from '@assets/icons/check-task.svg';
import { ReactComponent as LockSvg } from '@assets/icons/lock.svg';
import { assetPreviewThumbnails } from '@helpers/assetPreviewThumbnails';
import { sortedAWListAssetsToReviewInBatch } from '@components/Modals/ApprovalModal/utils';

import './AssetsSlider.less';

const ITEM_WIDTH = 88;

type AssetsSliderItemProps = {
  asset: AWAssetListItemDto;
  isActive: boolean;
  onClick: () => void;
};

function AssetsSliderItem({ asset, isActive, onClick }: AssetsSliderItemProps) {
  const decisionDisabled =
    asset?.isPaused ||
    asset.isFinished ||
    (asset.lockDecisions && asset.decision) ||
    !asset.canSubmitDecision;

  const decisionDisabledOpenAsset = asset.isPaused || asset.isFutureReview;

  const preparePreview = useMemo(() => {
    const res = assetPreviewThumbnails({
      url: asset.previewUrl || '',
      status: 'ready',
      type: asset.type,
      format: 'img'
    });

    if (typeof res === 'string')
      return <img draggable={false} src={res || ''} alt="" />;
    return res;
  }, [asset]);

  return (
    <Tooltip
      overlayClassName="assets-slider_tooltip"
      title={
        <>
          <div className="assets-slider_tooltip-name">
            {asset.name}
            <span className="assets-slider_tooltip-extension">
              .{asset.extension}
            </span>
          </div>
          <div className="assets-slider_tooltip-version">
            V{asset.versionsCount}
          </div>
        </>
      }
    >
      <div
        className={classNames('assets-slider_item', {
          'assets-slider_item--active': isActive,
          'assets-slider_item--disabled-open': decisionDisabledOpenAsset,
          'assets-slider_item--disabled': decisionDisabled || !!asset.decision
        })}
        onClick={onClick}
      >
        {decisionDisabled && (
          <div className="assets-slider_item-lock-icon">
            <LockSvg />
          </div>
        )}
        {preparePreview}
        {!!asset.decision && (
          <div className="assets-slider_item-icon">
            <ArrowCheckSvg />
          </div>
        )}
      </div>
    </Tooltip>
  );
}

export default function AssetsSlider() {
  const selectedVersion = useTypedSelector(
    ({ mediaViewer }) => mediaViewer.selectedVersion
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedVersionId = selectedVersion?.id;
  const batchId = selectedVersion?.approvalWorkflow?.batchId;
  const [isShowButtons, setIsShowButtons] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const password = selectedVersion?.id
    ? localStorage.getItem(
        `asset-review-password-${selectedVersion.approvalWorkflow?.batchId}`
      ) || localStorage.getItem(`asset-review-password-${selectedVersion.id}`)
    : null;
  const { data: approvalReviewInBatch } = useAWListAssetsToReviewInBatch({
    params: {
      batchId: batchId!,
      password
    }
  });

  const sortedApprovalListAssets = useMemo(() => {
    if (!approvalReviewInBatch?.assets?.length) return [];
    return sortedAWListAssetsToReviewInBatch(approvalReviewInBatch.assets);
  }, [approvalReviewInBatch]);

  const count = approvalReviewInBatch?.assets?.length || 0;

  const onPrev = () => {
    const content = contentRef.current;
    if (!content) return;
    const scrollToOffsetByWidth =
      (content.clientWidth / ITEM_WIDTH) * ITEM_WIDTH;
    content.scrollLeft -= scrollToOffsetByWidth + 2;
  };

  const onNext = () => {
    const content = contentRef.current;
    if (!content) return;
    const scrollToOffsetByWidth =
      (content.clientWidth / ITEM_WIDTH) * ITEM_WIDTH;
    content.scrollLeft += scrollToOffsetByWidth + 2;
  };

  useEffect(() => {
    const content = contentRef.current;
    if (!content) return;
    setIsShowButtons(content.scrollWidth > content.clientWidth);
  }, [setIsShowButtons, count]);

  useEffect(() => {
    const content = contentRef.current;
    if (!content) return;
    if (!sortedApprovalListAssets.length) return;
    if (!batchId) return;
    const activeLinkIndex = sortedApprovalListAssets.findIndex(
      (i) => i.versionId === selectedVersionId
    );
    const itemElement = content.children[activeLinkIndex];
    if (itemElement) {
      itemElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      });
    }
  }, [sortedApprovalListAssets, selectedVersionId]);

  if (count <= 1 || !batchId) return null;

  return (
    <div className="assets-slider">
      {isShowButtons && (
        <button
          className="assets-slider_btn assets-slider_btn-prev"
          type="button"
          onClick={onPrev}
        >
          <ArrowLeftSvg />
        </button>
      )}
      <div ref={contentRef} className="assets-slider_content">
        {sortedApprovalListAssets.map((item) => {
          const isActive = selectedVersionId === item.versionId;
          return (
            <AssetsSliderItem
              asset={item}
              isActive={isActive}
              onClick={() => {
                if (isActive || item.isPaused || item.isFutureReview) return;
                searchParams.set('reviewId', item.versionId);
                setSearchParams(searchParams);
              }}
              key={item.id}
            />
          );
        })}
      </div>
      {isShowButtons && (
        <button
          className="assets-slider_btn assets-slider_btn-next"
          type="button"
          onClick={onNext}
        >
          <ArrowRightSvg />
        </button>
      )}
    </div>
  );
}
