import React, { useCallback } from 'react';
import classNames from 'classnames';
import { message, Modal } from 'antd';
import { AWAssetListItemDto } from '@api/Api';
import { useAWSubmitBatchDecision } from '@hooks/queries/approval';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { ReactComponent as CloseSvg } from '@assets/icons/cross.svg';

import './ApprovalBulkActions.less';

type ApprovalBulkActionsProps = {
  assets: AWAssetListItemDto[];
  password: string | null;
  onClose: () => void;
};

const decisions = [
  {
    label: 'Approved',
    backgroundColor: '#A7D2A1',
    key: 'approved'
  },
  {
    label: 'Approved with feedback',
    backgroundColor: '#BFC5F4',
    key: 'approved_with_feedback'
  },
  {
    label: 'Changes required',
    backgroundColor: '#F7CAB0',
    key: 'changes_required'
  }
];

export default function ApprovalBulkActions({
  assets,
  password,
  onClose
}: ApprovalBulkActionsProps) {
  const { mutateAsync: submitBatchDecision, isLoading } =
    useAWSubmitBatchDecision({});

  const selectDecision = useCallback(
    async (decisionKey, confirmed = false) => {
      if (!assets.length) return;
      if (!confirmed) {
        Modal.confirm({
          onOk: () => selectDecision(decisionKey, true),
          width: 500,
          icon: null,
          centered: true,
          okText: 'Yes, submit',
          cancelText: 'No, continue review',
          closable: true,
          maskClosable: true,
          closeIcon: <CloseSvg />,
          content: (
            <div className="modal_container">
              <h2
                className="modal_container__title"
                style={{ fontSize: 22, marginBottom: '0.5em' }}
              >
                Is this your final decision?
              </h2>
              <p className="modal_container__subtitle">
                Make sure you leave feedback before submitting your decision.
                Once submitted, you will not be able to leave more comments or
                change your decision.
              </p>
            </div>
          )
        });
        return;
      }
      if (isLoading) return;
      try {
        await submitBatchDecision({
          assets: assets.map((item) => ({
            id: item.id,
            versionId: item.versionId,
            stageId: item.currentStageId
          })),
          password,
          decision: decisionKey
        });
        const decisionLabel = decisions.find(
          (x) => x.key === decisionKey
        )?.label;
        onClose();
        message.success({
          content: `Selected media has been marked as ${decisionLabel}`,
          className: 'message-dark-modal',
          icon: <SuccessSvg className="anticon" />,
          duration: 2
        });
      } catch (err: any) {
        message.error(err?.response?.data?.message || 'Something went wrong');
      }
    },
    [assets, password, isLoading, submitBatchDecision, onClose]
  );

  return (
    <div
      className={classNames('approval-bulk-actions', {
        'approval-bulk-actions--active': !!assets?.length
      })}
    >
      <div className="approval-bulk-actions_top">
        <div className="approval-bulk-actions_info">
          <div className="approval-bulk-actions_info-icon" />
          <div className="approval-bulk-actions_info-text">
            {assets?.length} media selected
          </div>
        </div>
        <div className="approval-bulk-actions_close" onClick={onClose} />
      </div>
      <div className="approval-bulk-actions_actions">
        {decisions.map((item) => (
          <div
            key={item.key}
            className={classNames('approval-bulk-actions_actions-item', {
              'approval-bulk-actions_actions-item--loading': isLoading
            })}
            onClick={() => selectDecision(item.key)}
          >
            <div
              className="approval-bulk-actions_actions-icon"
              style={{ backgroundColor: item.backgroundColor }}
            />
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
}
